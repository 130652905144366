import { Box, TextField, Autocomplete, Container } from '@mui/material';
import { useEffect, useState } from 'react';

interface HMI {
    label: string;
    letter: string;
}

const hmiOptions: { [key: string]: HMI[] } = {
    'SICHARGE D o mocy 160 / 180 / 240 / 300 kW': [
        { label: 'bez gniazda AC 22 kVA; bez przycisku awaryjnego wyłączenia', letter: "A" },
        { label: 'z gniazdem AC 22 kVA ; bez przycisku awaryjnego wyłączenia', letter: "B" },
        { label: 'bez gniazda AC 22 kVA ; z przyciskiem awaryjnego wyłączenia', letter: "C" },
        { label: 'z gniazdem AC 22 kVA ; z przyciskiem awaryjnego wyłączenia', letter: "D" },
    ],
    'SICHARGE D o mocy 360 i 400 kW': [
        { label: 'bez gniazda AC 22 kVA; bez przycisku awaryjnego wyłączenia', letter: "A" },
        { label: 'bez gniazda AC 22 kVA ; z przyciskiem awaryjnego wyłączenia', letter: "C" },
    ],
};

interface ChooseHMIProps {
    selectedCharger: string | null;
    selectedHMI: string | null;
    selectedCounter: string | null;
    setSelectedHMI: (hmi: string | null) => void;
}

export const ChooseHMI: React.FC<ChooseHMIProps> = ({
    selectedCharger,
    selectedHMI,
    selectedCounter,
    setSelectedHMI
}) => {
    const [filteredOptions, setFilteredOptions] = useState<HMI[]>([]);
    const isDistributor = selectedCharger === 'Dystrybutor SICHARGE D';

    useEffect(() => {
        let options = selectedCharger ? hmiOptions[selectedCharger] || [] : [];
        if (selectedCharger === 'SICHARGE D o mocy 160 / 180 / 240 / 300 kW' && selectedCounter === '3') {
            options = options.filter(option => option.letter !== 'C' && option.letter !== 'D');
        }

        setFilteredOptions(options);
        setSelectedHMI(null);
    }, [selectedCharger, selectedCounter, setSelectedHMI]);

    const selectedHMIObject = filteredOptions.find(hmi => hmi.letter === selectedHMI) || null;

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                {!isDistributor ? (
                    <Autocomplete
                        value={selectedHMIObject}
                        disabled={!selectedCharger}
                        onChange={(_, newValue) => {
                            setSelectedHMI(newValue?.letter || null);
                        }}
                        options={filteredOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField {...params} label="Wybierz HMI" />}
                    />
                ) : null}
            </Box>
        </Container>
    );
};
