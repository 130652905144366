import { Box, TextField, Autocomplete, Container } from '@mui/material';
import { useEffect, useState } from 'react';


interface ExtraOption {
    label: string;
    serial_number_end: string;
}

const extraOptions: { [key: string]: ExtraOption[] } = {
    'SICHARGE D o mocy 160 / 180 / 240 / 300 kW': [
        { label: 'Główny licznik od strony zasilania', serial_number_end: "M00" },
        { label: 'EMC klasy B', serial_number_end: "E00" },
        { label: 'Czujnik zderzeniowy', serial_number_end: "A10" },
        { label: 'Aktywacja lokalnego, zewnętrznego zarządzania energią', serial_number_end: "S00" },
        { label: 'Regulacja poziomu hałasu (tryb cichy)', serial_number_end: "S10" },
    ],
    'SICHARGE D o mocy 360 i 400 kW': [
        { label: 'Główny licznik od strony zasilania', serial_number_end: "M00" },
        { label: 'EMC klasy B', serial_number_end: "E00" },
        { label: 'Czujnik zderzeniowy', serial_number_end: "A10" },
        { label: 'Aktywacja lokalnego, zewnętrznego zarządzania energią', serial_number_end: "S00" },
        { label: 'Regulacja poziomu hałasu (tryb cichy)', serial_number_end: "S10" },
    ],
};

interface ChooseExtraOptionsProps {
    selectedCharger: string | null;
    selectedExtraOption: string | null;
    setSelectedExtraOption: (extraOption: string | null) => void;
}

export const ChooseExtraOptions: React.FC<ChooseExtraOptionsProps> = ({
    selectedCharger,
    selectedExtraOption,
    setSelectedExtraOption
}) => {
    const [filteredOptions, setFilteredOptions] = useState<ExtraOption[]>([]);
    const isDistributor = selectedCharger === 'Dystrybutor SICHARGE D';

    useEffect(() => {
        let options = selectedCharger ? extraOptions[selectedCharger] || [] : [];
        setFilteredOptions(options);
        setSelectedExtraOption(null);
    }, [selectedCharger, setSelectedExtraOption]);

    const selectedExtraOptionObject = filteredOptions.find(extraOption => extraOption.serial_number_end === selectedExtraOption) || null;

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                {!isDistributor ? (
                    <Autocomplete
                        value={selectedExtraOptionObject}
                        disabled={!selectedCharger}
                        onChange={(_, newValue) => {
                            setSelectedExtraOption(newValue?.serial_number_end || null);
                        }}
                        options={filteredOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField {...params} label="Dodatkowe opcje" />}
                    />
                ) : null}
            </Box>
        </Container>
    );
}