import { Box, TextField, Autocomplete, Container } from '@mui/material';
import { useEffect, useState } from 'react';

interface CableManagement {
    label: string;
    number: string;
}

const cableManagementOptions: { [key: string]: CableManagement[] } = {
    'SICHARGE D o mocy 160 / 180 / 240 / 300 kW': [
        { label: 'bez linek podciągających kabel', number: "0" },
        { label: 'z linkami podciągającymi kabel', number: "1" },
    ],
    'SICHARGE D o mocy 360 i 400 kW': [
        { label: 'bez linek podciągających kabel', number: "0" },
        { label: 'z linkami podciągającymi kabel', number: "1" },
        { label: 'z linkami podciągającymi kabel', number: "2" },
    ],
    'Dystrybutor SICHARGE D': [
        { label: 'z linkami podciągającymi kabel', number: "1" },
    ],
};

interface ChooseCableManagementProps {
    selectedCharger: string | null;
    selectedConnectorLeft: string | null;
    selectedCableManagement: string | null;
    setSelectedCableManagement: (cableManagement: string | null) => void;
}

export const ChooseCableManagement: React.FC<ChooseCableManagementProps> = ({
    selectedCharger,
    selectedConnectorLeft,
    selectedCableManagement,
    setSelectedCableManagement
}) => {
    const [filteredOptions, setFilteredOptions] = useState<CableManagement[]>([]);


    useEffect(() => {
        let options = selectedCharger ? cableManagementOptions[selectedCharger] || [] : [];

        if (selectedCharger === 'SICHARGE D o mocy 360 i 400 kW') {
            if (selectedConnectorLeft === 'C') {
                options = options.filter(option => option.number === "1" || option.number === "0");
            } else {
                options = options.filter(option => option.number === "2" || option.number === "0");
            }
        }

        setFilteredOptions(options);
        setSelectedCableManagement(null);
    }, [selectedCharger, selectedConnectorLeft, setSelectedCableManagement]);

    const selectedCableManagementObject = filteredOptions.find(cableManagement => cableManagement.number === selectedCableManagement) || null;

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                <Autocomplete
                    options={filteredOptions}
                    disabled={!selectedCharger}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => setSelectedCableManagement(value?.number || null)}
                    renderInput={(params) => (
                        <TextField {...params} label="Wybierz zarządzanie kablem" variant="outlined" fullWidth />
                    )}
                    value={selectedCableManagementObject}
                />
            </Box>
        </Container>
    );
};
