import { Box, TextField, Autocomplete, Container } from '@mui/material';
import { useEffect } from 'react';

interface ConnectorLeft {
    label: string;
    letter: string;
}

const connectorOptions: { [key: string]: ConnectorLeft[] } = {
    'SICHARGE D o mocy 160 / 180 / 240 / 300 kW': [
        { label: 'CCS2, 5 m; 250 A / max. 400 A w piku', letter: "A" },
        { label: 'CCS2, 3,1 m; 250 A / max. 400 A w piku', letter: "E" },
        { label: 'CHAdeMO, 3.1 m, 125 A', letter: "G" },
        { label: 'CHAdeMO, 5 m, 125 A', letter: "H" },
    ],
    'SICHARGE D o mocy 360 i 400 kW': [
        { label: 'CCS2, 5m, 500A w piku (niechłodzony)', letter: "B" },
        { label: 'CCS2, 5m, 500A chłodzony płynem', letter: "C" },
    ],
    'Dystrybutor SICHARGE D': [
        { label: 'CCS2, 5 m; 250 A / max. 400 A w piku', letter: "A" },
    ],
};

const excludedConnectors: { [key: string]: string[] } = {
    "3": ["G", "H"],
};

interface ChooseConnectorLeftProps {
    selectedCharger: string | null;
    selectedConnectorLeft: string | null;
    selectedConnectorRight: string | null;
    setSelectedConnectorLeft: (connectorLeft: string | null) => void;
    setSelectedConnectorRight: (connectorRight: string | null) => void;
    selectedCounter: string | null;
}

export const ChooseConnectorLeft: React.FC<ChooseConnectorLeftProps> = ({
    selectedCharger,
    selectedConnectorLeft,
    selectedConnectorRight,
    setSelectedConnectorLeft,
    setSelectedConnectorRight,
    selectedCounter
}) => {
    const connectors = selectedCharger ? connectorOptions[selectedCharger] || [] : [];

    useEffect(() => {
        if (selectedCharger === 'SICHARGE D o mocy 360 i 400 kW' && selectedConnectorRight && selectedConnectorLeft !== selectedConnectorRight) {
            setSelectedConnectorLeft(selectedConnectorRight);
        }
    }, [selectedCharger, selectedConnectorRight, selectedConnectorLeft, setSelectedConnectorLeft]);

    useEffect(() => {
        setSelectedConnectorLeft(null);
    }, [selectedCharger, setSelectedConnectorLeft]);

    const excluded = selectedCounter ? excludedConnectors[selectedCounter] || [] : [];
    const filteredConnectors = connectors.filter(connector => !excluded.includes(connector.letter));

    const selectedConnectorLeftObject = filteredConnectors.find(connectorLeft => connectorLeft.letter === selectedConnectorLeft) || null;

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                <Autocomplete
                    options={filteredConnectors}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => {
                        const selected = value?.letter || null;
                        setSelectedConnectorLeft(selected);
                        if (selectedCharger === 'SICHARGE D o mocy 360 i 400 kW') {
                            setSelectedConnectorRight(selected);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Wybierz złącze po lewej stronie" variant="outlined" fullWidth />
                    )}
                    sx={{ mb: 4 }}
                    disabled={!selectedCharger}
                    value={selectedConnectorLeftObject}
                />
            </Box>
        </Container>
    );
};
