import { Box, TextField, Autocomplete, Container } from '@mui/material';
import { useEffect, useState } from 'react';

interface PaymentOption {
    label: string;
    letter: string;
}

const paymentOptions: { [key: string]: PaymentOption[] } = {
    'SICHARGE D o mocy 160 / 180 / 240 / 300 kW': [
        { label: 'z czytnikiem RFID; przygotowanie pod terminal za szybą (inne przygotowanie do ustalenia)', letter: "B" },
        { label: 'z czytnikiem RFID; Terminal CCV (CCV KNB)', letter: "C" },
        { label: 'z czytnikiem RFID; Terminal CCV CCV (VR Payment)', letter: "D" },
        { label: 'z czytnikiem RFID; Terminal CCV CCV (Payone)', letter: "E" },
        { label: 'z czytnikiem RFID; Terminal Worldline Valina', letter: "H" },
        { label: 'z czytnikiem RFID; Terminal Payter P66', letter: "K" },
        { label: 'z czytnikiem RFID; Terminal Castles UPT1000F (Seitatech)', letter: "N" },
    ],
    'SICHARGE D o mocy 360 i 400 kW': [
        { label: 'z czytnikiem RFID; przygotowanie pod terminal za szybą (inne przygotowanie do ustalenia)', letter: "B" },
        { label: 'z czytnikiem RFID; Terminal CCV (CCV KNB)', letter: "C" },
        { label: 'z czytnikiem RFID; Terminal CCV CCV (VR Payment)', letter: "D" },
        { label: 'z czytnikiem RFID; Terminal CCV CCV (Payone)', letter: "E" },
        { label: 'z czytnikiem RFID; Terminal Worldline Valina', letter: "H" },
        { label: 'z czytnikiem RFID; Terminal Payter P66', letter: "K" },
        { label: 'z czytnikiem RFID; Terminal Castles UPT1000F (Seitatech)', letter: "N" },
    ],
};

interface ChoosePaymentOptionsProps {
    selectedCharger: string | null;
    selectedPaymentOption: string | null;
    selectedCounter: string | null;
    setSelectedPaymentOption: (paymentOption: string | null) => void;
}

export const ChoosePaymentOptions: React.FC<ChoosePaymentOptionsProps> = ({
    selectedCharger,
    selectedPaymentOption,
    selectedCounter,
    setSelectedPaymentOption
}) => {
    const [filteredOptions, setFilteredOptions] = useState<PaymentOption[]>([]);
    const isDistributor = selectedCharger === 'Dystrybutor SICHARGE D';

    useEffect(() => {
        let options = selectedCharger ? paymentOptions[selectedCharger] || [] : [];
        if ((selectedCharger === 'SICHARGE D o mocy 160 / 180 / 240 / 300 kW' || selectedCharger === 'SICHARGE D o mocy 360 i 400 kW') && selectedCounter === '3') {
            options = options.filter(option => option.letter !== 'K' && option.letter !== 'N');
        }

        setFilteredOptions(options);
        setSelectedPaymentOption(null);
    }, [selectedCharger, setSelectedPaymentOption, selectedCounter]);

    const selectedPaymentOptionObject = filteredOptions.find(paymentOption => paymentOption.letter === selectedPaymentOption) || null;

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                {!isDistributor ? (
                    <Autocomplete
                        value={selectedPaymentOptionObject}
                        disabled={!selectedCharger}
                        onChange={(_, newValue) => {
                            setSelectedPaymentOption(newValue?.letter || null);
                        }}
                        options={filteredOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField {...params} label="Wybierz opcję płatności" />}
                    />
                ) : null}
            </Box>
        </Container>
    );
};
