import React from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import MainPage from './MainPage';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <MainPage />
    </ThemeProvider>
  );
}