import React from 'react';
import { Box, TextField, Autocomplete, Container } from '@mui/material';

interface Counter {
    label: string;
    number: string;
}

const counterOptions: Counter[] = [
    { label: 'zliczanie oparte o pomiar prądu i napięcia na module mocy', number: "0" },
    { label: 'z zainstalowanym licznikiem energii bez certyfikacji', number: "1" },
    { label: 'z zainstalowanym licznikiem energii z certyfikacją MID', number: "2" },
    { label: 'z zainstalowanym licznikiem energii z certyfikacją ERK', number: "3" },
];

interface ChooseCounterProps {
    selectedCounter: string | null;
    setSelectedCounter: (counter: string | null) => void;
}

export const ChooseCounter: React.FC<ChooseCounterProps> = ({ selectedCounter, setSelectedCounter }) => {
    const selectedCounterObject = counterOptions.find(counter => counter.number === selectedCounter) || null;

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                <Autocomplete
                    options={counterOptions}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => setSelectedCounter(value?.number || null)}
                    renderInput={(params) => (
                        <TextField {...params} label="Wybierz typ licznika" variant="outlined" fullWidth />
                    )}
                    sx={{ mb: 4 }}
                    disabled={false}
                    value={selectedCounterObject}
                />
            </Box>
        </Container>
    );
};
