import React from 'react';
import { Container, Box, TextField, Autocomplete } from '@mui/material';

interface Charger {
    first_sn_part: string;
    label: string;
}

const chargers: Charger[] = [
    { first_sn_part: "8EM500", label: 'SICHARGE D o mocy 160 / 180 / 240 / 300 kW' },
    { first_sn_part: "8EM500", label: 'SICHARGE D o mocy 360 i 400 kW' },
    { first_sn_part: "8EM5200", label: 'Dystrybutor SICHARGE D' },
];

interface PickChargerProps {
    setSelectedCharger: (charger: string | null, firstSnPart: string | null) => void;
    setSelectedChargerSN: (firstSnPart: string | null) => void;
}

export const PickCharger: React.FC<PickChargerProps> = ({ setSelectedCharger }) => {
    const handleChange = (event: React.SyntheticEvent<Element, Event>, value: Charger | null) => {
        setSelectedCharger(value?.label || null, value?.first_sn_part || null);
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                <Autocomplete
                    options={chargers}
                    getOptionLabel={(option) => option.label}
                    onChange={handleChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Wybierz typ ładowarki" variant="outlined" fullWidth />
                    )}
                    sx={{ mb: 4 }}
                />
            </Box>
        </Container>
    );
}
