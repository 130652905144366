import { Box, TextField, Autocomplete, Container } from '@mui/material';
import { useEffect, useState } from 'react';

interface DPA {
    label: string;
    number: string;
}

const dpaOptions: { [key: string]: DPA[] } = {
    'SICHARGE D o mocy 160 / 180 / 240 / 300 kW': [
        { label: 'przygotowanie ładowarki pod 2 dystrybutory (gotowość do podłączenia) - High Flex DPA', number: "0" },
        { label: 'bez przygotowania ładowarki pod 2 dystrybutory (gotowość do podłączenia) - High Flex DPA', number: "1" },
        { label: 'bez przygotowania ładowarki pod 2 dystrybutory (gotowość do podłączenia) - Low Flex DPA', number: "2" },
    ],
    'SICHARGE D o mocy 360 i 400 kW': [
        { label: 'przygotowanie ładowarki pod 2 dystrybutory (gotowość do podłączenia) - High Flex DPA', number: "0" },
        { label: 'bez przygotowania ładowarki pod 2 dystrybutory (gotowość do podłączenia) - High Flex DPA', number: "1" },
        { label: 'bez przygotowania ładowarki pod 2 dystrybutory (gotowość do podłączenia) - Low Flex DPA', number: "2" },
    ],
};

interface ChooseDPAProps {
    selectedCharger: string | null;
    selectedDPA: string | null;
    setSelectedDPA: (dpa: string | null) => void;
}

export const ChooseDPA: React.FC<ChooseDPAProps> = ({
    selectedCharger,
    selectedDPA,
    setSelectedDPA
}) => {
    const [filteredOptions, setFilteredOptions] = useState<DPA[]>([]);
    const isDistributor = selectedCharger === 'Dystrybutor SICHARGE D';

    useEffect(() => {
        let options = selectedCharger ? dpaOptions[selectedCharger] || [] : [];

        setFilteredOptions(options);
        setSelectedDPA(null);
    }, [selectedCharger, setSelectedDPA]);

    const selectedDPAObject = filteredOptions.find(dpa => dpa.number === selectedDPA) || null;

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                {!isDistributor ? (
                    <Autocomplete
                        value={selectedDPAObject}
                        disabled={!selectedCharger}
                        onChange={(_, newValue) => {
                            setSelectedDPA(newValue?.number || null);
                        }}
                        options={filteredOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField {...params} label="Wybierz DPA" />}
                    />
                ) : null}
            </Box>
        </Container>
    );
}