import React, { useEffect } from 'react';
import { Box, TextField, Autocomplete, Container } from '@mui/material';

interface Power {
    label: string;
    number: string;
}

const powerOptions: { [key: string]: Power[] } = {
    'SICHARGE D o mocy 160 / 180 / 240 / 300 kW': [
        { label: '160 kW', number: "0" },
        { label: '180 kW', number: "1" },
        { label: '240 kW', number: "3" },
        { label: '300 kW', number: "5" },
    ],
    'SICHARGE D o mocy 360 i 400 kW': [
        { label: '360 kW', number: "7" },
        { label: '400 kW', number: "8" },
    ],
};

interface ChoosePowerProps {
    selectedCharger: string | null;
    selectedPower: string | null;
    setSelectedPower: (power: string | null) => void;
}

export const ChoosePower: React.FC<ChoosePowerProps> = ({ selectedCharger, selectedPower, setSelectedPower }) => {
    const powers = selectedCharger ? powerOptions[selectedCharger] || [] : [];
    const isDistributor = selectedCharger === 'Dystrybutor SICHARGE D';

    useEffect(() => {
        setSelectedPower(null);
    }, [selectedCharger, setSelectedPower]);

    const selectedPowerObject = powers.find(power => power.number === selectedPower) || null;

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                {isDistributor ? (
                    <>
                    </>
                ) : (
                    <Autocomplete
                        options={powers}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, value) => setSelectedPower(value?.number || null)}
                        renderInput={(params) => (
                            <TextField {...params} label="Wybierz moc ładowarki" variant="outlined" fullWidth />
                        )}
                        sx={{ mb: 4 }}
                        disabled={!selectedCharger}
                        value={selectedPowerObject}
                    />
                )}
            </Box>
        </Container>
    );
};
