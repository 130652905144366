import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { PickCharger } from './components/PickCharger';
import { ChoosePower } from './components/ChoosePower';
import { ChooseCounter } from './components/ChooseCounter';
import { ChooseConnectorLeft } from './components/ChooseConnectorLeft';
import { ChooseConnectorRight } from './components/ChooseConnectorRight';
import { ChooseCableManagement } from './components/ChooseCableManagement';
import { ChooseDPA } from './components/ChooseDPA';
import { ChooseHMI } from './components/ChooseHMI';
import { ChoosePaymentOptions } from './components/ChoosePaymentOptions';
import { ChooseExtraOptions } from './components/ChooseExtraOptions';
import { CreateSerialNumber } from './components/CreateSerialNumber';
import { motion, AnimatePresence } from 'framer-motion';

export function MainPage() {
  const [selectedCharger, setSelectedCharger] = useState<string | null>(null);
  const [selectedChargerSN, setSelectedChargerSN] = useState<string | null>(null);
  const [selectedPower, setSelectedPower] = useState<string | null>(null);
  const [selectedCounter, setSelectedCounter] = useState<string | null>(null);
  const [selectedConnectorLeft, setSelectedConnectorLeft] = useState<string | null>(null);
  const [selectedConnectorRight, setSelectedConnectorRight] = useState<string | null>(null);
  const [selectedCableManagement, setSelectedCableManagement] = useState<string | null>(null);
  const [selectedDPA, setSelectedDPA] = useState<string | null>(null);
  const [selectedHMI, setSelectedHMI] = useState<string | null>(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<string | null>(null);
  const [selectedExtraOption, setSelectedExtraOption] = useState<string | null>(null);

  const handleChargerChange = (chargerLabel: string | null, chargerSN: string | null) => {
    setSelectedCharger(chargerLabel);
    setSelectedChargerSN(chargerSN);
    setSelectedPower(null);
    setSelectedCounter(null);
    setSelectedConnectorLeft(null);
    setSelectedConnectorRight(null);
    setSelectedCableManagement(null);
    setSelectedDPA(null);
    setSelectedHMI(null);
    setSelectedPaymentOption(null);
    setSelectedExtraOption(null);
  };
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Skonfiguruj swoją ładowarkę
      </Typography>
      <AnimatePresence>
        <motion.div
          key="pick-charger"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: selectedCharger ? 0.5 : 1, y: selectedCharger ? -10 : 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
        >
          <PickCharger setSelectedCharger={handleChargerChange} setSelectedChargerSN={setSelectedChargerSN} />
        </motion.div>

        {selectedCharger && (
          <motion.div
            key="choose-power"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: selectedPower ? 0.5 : 1, y: selectedPower ? -10 : 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <ChoosePower selectedCharger={selectedCharger} selectedPower={selectedPower} setSelectedPower={setSelectedPower} />
          </motion.div>
        )}

        {(selectedPower || (selectedCharger === 'Dystrybutor SICHARGE D')) && (
          <motion.div
            key="choose-counter"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: selectedCounter ? 0.5 : 1, y: selectedCounter ? -10 : 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <ChooseCounter selectedCounter={selectedCounter} setSelectedCounter={setSelectedCounter} />
          </motion.div>
        )}

        {(selectedCounter || (selectedCharger === 'Dystrybutor SICHARGE D' && selectedCounter)) && (
          <motion.div
            key="choose-connector-left"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: selectedConnectorLeft ? 0.5 : 1, y: selectedConnectorLeft ? -10 : 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <ChooseConnectorLeft
              selectedCharger={selectedCharger}
              selectedConnectorLeft={selectedConnectorLeft}
              selectedConnectorRight={selectedConnectorRight}
              setSelectedConnectorLeft={setSelectedConnectorLeft}
              setSelectedConnectorRight={setSelectedConnectorRight}
              selectedCounter={selectedCounter}
            />
          </motion.div>
        )}

        {(selectedConnectorLeft || (selectedCharger === 'Dystrybutor SICHARGE D' && selectedConnectorLeft)) && (
          <motion.div
            key="choose-connector-right"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: selectedConnectorRight ? 0.5 : 1, y: selectedConnectorRight ? -10 : 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <ChooseConnectorRight
              selectedCharger={selectedCharger}
              selectedConnectorRight={selectedConnectorRight}
              setSelectedConnectorRight={setSelectedConnectorRight}
              selectedConnectorLeft={selectedConnectorLeft}
            />
          </motion.div>
        )}

        {(selectedConnectorRight || (selectedCharger === 'Dystrybutor SICHARGE D' && selectedConnectorRight)) && (
          <motion.div
            key="choose-cable-management"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: selectedCableManagement ? 0.5 : 1, y: selectedCableManagement ? -10 : 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <ChooseCableManagement
              selectedCharger={selectedCharger}
              selectedCableManagement={selectedCableManagement}
              setSelectedCableManagement={setSelectedCableManagement}
              selectedConnectorLeft={selectedConnectorLeft}
            />
          </motion.div>
        )}

        {selectedCableManagement && (
          <motion.div
            key="choose-dpa"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: selectedDPA ? 0.5 : 1, y: selectedDPA ? -10 : 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <ChooseDPA
              selectedCharger={selectedCharger}
              selectedDPA={selectedDPA}
              setSelectedDPA={setSelectedDPA}
            />
          </motion.div>
        )}

        {selectedDPA && (
          <motion.div
            key="choose-hmi"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: selectedHMI ? 0.5 : 1, y: selectedHMI ? -10 : 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <ChooseHMI
              selectedCharger={selectedCharger}
              selectedHMI={selectedHMI}
              selectedCounter={selectedCounter}
              setSelectedHMI={setSelectedHMI}
            />
          </motion.div>
        )}

        {selectedHMI && (
          <motion.div
            key="choose-payment-options"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: selectedPaymentOption ? 0.5 : 1, y: selectedPaymentOption ? -10 : 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <ChoosePaymentOptions
              selectedCharger={selectedCharger}
              selectedPaymentOption={selectedPaymentOption}
              selectedCounter={selectedCounter}
              setSelectedPaymentOption={setSelectedPaymentOption}
            />
          </motion.div>
        )}

        {selectedPaymentOption && (
          <motion.div
            key="choose-extra-options"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: selectedExtraOption ? 0.5 : 1, y: selectedExtraOption ? -10 : 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <ChooseExtraOptions
              selectedCharger={selectedCharger}
              selectedExtraOption={selectedExtraOption}
              setSelectedExtraOption={setSelectedExtraOption}
            />
          </motion.div>
        )}

        {(selectedExtraOption || (selectedCharger === 'Dystrybutor SICHARGE D' && selectedCableManagement)) && (
          <motion.div
            key="create-serial-number"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <CreateSerialNumber
              selectedChargerSN={selectedChargerSN}
              selectedPower={selectedPower}
              selectedCounter={selectedCounter}
              selectedConnectorLeft={selectedConnectorLeft}
              selectedConnectorRight={selectedConnectorRight}
              selectedCableManagement={selectedCableManagement}
              selectedDPA={selectedDPA}
              selectedHMI={selectedHMI}
              selectedPaymentOption={selectedPaymentOption}
              selectedExtraOption={selectedExtraOption}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}

export default MainPage;
