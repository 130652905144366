import { Box, TextField, Autocomplete, Container, Typography } from '@mui/material';
import { useEffect } from 'react';

interface ConnectorRight {
    label: string;
    letter: string;
}

const connectorOptions: { [key: string]: ConnectorRight[] } = {
    'SICHARGE D o mocy 160 / 180 / 240 / 300 kW': [
        { label: 'CCS2, 5 m, 250 A / max. 400 A w piku', letter: "A" },
        { label: 'CCS2, 5 m, 500 A chłodzony płynem', letter: "C" },
        { label: 'CCS2, 3,1 m; 250 A / max. 400 A w piku', letter: "E" },
        { label: 'CCS2, 3,1 m; 500 A chłodzony płynem', letter: "F" },
    ],
    'SICHARGE D o mocy 360 i 400 kW': [
        { label: 'CCS2, 5m, 500A w piku (niechłodzony)', letter: "B" },
        { label: 'CCS2, 5m, 500A chłodzony płynem', letter: "C" },
    ],
    'Dystrybutor SICHARGE D': [
        { label: 'CCS2, 5 m; 250 A / max. 400 A w piku', letter: "A" },
    ],
};

interface ChooseConnectorRightProps {
    selectedCharger: string | null;
    selectedConnectorRight: string | null;
    setSelectedConnectorRight: (connectorRight: string | null) => void;
    selectedConnectorLeft: string | null;
}

export const ChooseConnectorRight: React.FC<ChooseConnectorRightProps> = ({
    selectedCharger,
    selectedConnectorRight,
    setSelectedConnectorRight,
    selectedConnectorLeft
}) => {
    const connectors = selectedCharger ? connectorOptions[selectedCharger] || [] : [];

    useEffect(() => {
        if (selectedCharger === 'SICHARGE D o mocy 360 i 400 kW' && selectedConnectorLeft && selectedConnectorRight !== selectedConnectorLeft) {
            setSelectedConnectorRight(selectedConnectorLeft);
        }
    }, [selectedCharger, selectedConnectorLeft, selectedConnectorRight, setSelectedConnectorRight]);

    useEffect(() => {
        setSelectedConnectorRight(null);
    }, [selectedCharger, setSelectedConnectorRight]);

    const selectedConnectorRightObject = connectors.find(connectorRight => connectorRight.letter === selectedConnectorRight) || null;

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                <Autocomplete
                    options={connectors}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => setSelectedConnectorRight(value?.letter || null)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Wybierz złącze po prawej stronie"
                            variant="outlined"
                            fullWidth
                            disabled={selectedCharger === 'SICHARGE D o mocy 360 i 400 kW'}
                        />
                    )}
                    disabled={!selectedCharger || selectedCharger === 'SICHARGE D o mocy 360 i 400 kW'}
                    value={selectedConnectorRightObject}
                />
                {selectedCharger === 'SICHARGE D o mocy 360 i 400 kW' && (
                    <Typography sx={{
                        color: 'red',
                        position: 'relative',
                        top: '100%',
                        zIndex: 1,
                        mt: 1,
                        width: '100%',
                        mb: 1
                    }}>
                        To złącze jest automatycznie synchronizowane z lewym złączem i nie może być zmieniane.
                    </Typography>
                )}
            </Box>
        </Container>
    );
};
