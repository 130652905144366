import { Box, Container, Button, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { useState } from 'react';

interface CreateSerialNumberProps {
    selectedChargerSN: string | null;
    selectedPower: string | null;
    selectedCounter: string | null;
    selectedConnectorLeft: string | null;
    selectedConnectorRight: string | null;
    selectedCableManagement: string | null;
    selectedDPA: string | null;
    selectedHMI: string | null;
    selectedPaymentOption: string | null;
    selectedExtraOption: string | null;
}

export const CreateSerialNumber: React.FC<CreateSerialNumberProps> = ({
    selectedChargerSN,
    selectedPower,
    selectedCounter,
    selectedConnectorLeft,
    selectedConnectorRight,
    selectedCableManagement,
    selectedDPA,
    selectedHMI,
    selectedPaymentOption,
    selectedExtraOption
}) => {
    const [open, setOpen] = useState(false);
    const [serialNumber, setSerialNumber] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const generateSerialNumber = () => {
        let generatedSerialNumber = '';
        if (selectedChargerSN === '8EM500') {
            generatedSerialNumber = selectedChargerSN + selectedPower + '-' + selectedCounter + selectedConnectorLeft + selectedConnectorRight + selectedCableManagement + selectedDPA + '-0' + selectedHMI + selectedPaymentOption + '0-' + selectedExtraOption;
        }
        else {
            generatedSerialNumber = selectedChargerSN + '-' + selectedCounter + selectedConnectorLeft + selectedConnectorRight + selectedCableManagement + '0-0AA0';
        }

        generatedSerialNumber = generatedSerialNumber.replace(/\s/g, '');
        setSerialNumber(generatedSerialNumber);
        handleOpen();
    };

    return (
        <Container maxWidth="sm">
            <Button variant="outlined" onClick={generateSerialNumber}>Wygeneruj numer seryjny</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Dziękujemy za skorzystanie z naszego konfiguratora</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Numer seryjny Twojego urządzenia: {serialNumber}
                    </DialogContentText>
                    <Box mt={2}>
                        <DialogContentText>
                            Skontaktuj się z nami abyśmy mogli wycenić Twoje urządzenie: +1-800-123-4567
                        </DialogContentText>
                    </Box>
                    <Box mt={2}>
                        <Button variant="outlined" onClick={handleClose}>Zamknij</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Container>
    );
}
